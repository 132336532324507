import './App.css'
import { useState, useRef, useEffect, useCallback } from 'react'

const rows = 6
const cols = 5

const allowedChars = ['q','w','e','r','t','y','u','i','o','p','a','s','d','f','g','h','j','k','l','ö','ä','z','x','c','v','b','n','m','Backspace']



function App() {

  const [ guess, setGuess ] = useState([])
  const [ game, setGame ] = useState()
  const [notification, setNotification] = useState({ type: '', message: '', visible: false })
  const mounted = useRef(null)

  const fetchGameData = useCallback(async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + '/game', {
      credentials: 'include'
    })
    if (response.ok) {
      const game = await response.json()
      mounted.current && setGame(game)
    }
  }, [ ])

  const sendWord = useCallback(async (e) => {
    e.preventDefault()

    const guessResponse = await fetch(process.env.REACT_APP_API_URL + '/guess', {
      method: 'POST',
      body: JSON.stringify(guess),
      credentials: 'include'
    })

    if (guessResponse.ok) {
      fetchGameData()
      const notificationJson = await guessResponse.json()

      if (notificationJson.type !== 'error') {
        setGuess([])
      }

      mounted.current && setNotification({ ...notificationJson, visible: true })

      setTimeout(() => {
        mounted.current && setNotification({ ...notificationJson, type: '', visible: false })
      }, 4000)
    }
  }, [ guess, fetchGameData ])

  const handleCharInput = useCallback((e) => {

    if (game?.isComplete) return

    const char = e.key ?? e.target.value

    // prevent default action for Enter key
    if (char === 'Enter') {
      sendWord(e)
      return
    }

    // prevent default action for other keys
    if (!allowedChars.includes(char)) return

    if (char === 'Backspace' && guess.length > 0) {
      setGuess(prev => prev.slice(0, -1))
    } else if (char !== 'Backspace' && guess.length < cols){
      setGuess(prev => prev.concat(char))
    }
  }, [ sendWord, guess, game?.isComplete ])


  useEffect(() => {
    fetchGameData()
  }, [fetchGameData])

  useEffect(() => {
    mounted.current = true
    document.addEventListener('keydown', handleCharInput)

    return () => {
      mounted.current = false
      document.removeEventListener('keydown', handleCharInput)
    }
  }, [ handleCharInput ] )

  const getCell = (row, col) => {
    if (!game) return null

    // check active quess
    if (!game.guesses[row] && game.guesses.length === row && guess.length <= cols && typeof guess[col] == 'string') {
      return <span className="letter" data-state="guess">{ guess[col] }</span>
    }

    // check previous guesses
    if (!game || !game.guesses[row] || !game.guesses[row][col] ) return null

    const [ char, status ] = game.guesses[row][col]
    return <span className="letter" data-state={status}>{ char }</span>
  }

  const renderId = () => {
    const { date } = game || { date: null }
    const timestamp = parseInt(date.replace(/-/g, ''))
    return '#' + (timestamp & 0xFFFFFFFF).toString(32).toUpperCase()
  }

  const renderGuessCount = () => {
    if (!game) return '*'

    if (game.isComplete && game.isWon) {
      return `${game.guesses.length}/${rows}`
    }

    if (game.isComplete && !game.isWon) {
      return `x/${rows}`
    }

    return `*/${rows}`
  }

  const handleShare = async () => {
    const getEmoji = (status) => {
      switch (status) {
        case 'correct': return '🟩'
        case 'found': return '🟨'
        case 'missed': return '⬛️'
        default: return ''
      }
    }

    const initStr = 'SS ' + renderId(game) + ' ' + renderGuessCount() + '\n'

    // share results to clipboard
    const shareStr = game.guesses.reduce((acc, row) => {
      acc += row.map((char) => getEmoji(char[1])).join('') + '\n'
      return acc
    }, initStr) + ' ' + renderStreak()


    copyToClipboard(shareStr)
  }

  const copyToClipboard = useCallback(async (str) => {
    try {
      await navigator.clipboard.writeText(str)
      mounted.current && setNotification({ type: 'success', message: 'Tulos kopioitu leikepöydälle', visible: true })
      setTimeout(() => {
        mounted.current && setNotification({ type: 'success', message: '', visible: false })
      }, 4000)
    } catch (err) {
      mounted.current && setNotification({ type: 'error', message: 'Tuloksen kopioiminen epäonnistui', visible: true })
      setTimeout(() => {
        mounted.current && setNotification({ type: 'error', message: '', visible: false })
      }, 4000)
    }
  }, [ ])

  const renderStreak = () => {
    return `🔥 ${ game?.streak || '0' }`
  }

  const isSelected = (char) => {
    return guess.includes(char)
  }

  const getStatus = (char) => {
    if (!game || !game.guesses || !game.guesses.length > 0) return

    const chars = game.guesses.flat()

    // char may have multiple matches, but we only need to check the most valuable one
    if (chars.some(([c, status]) => (char === c && status === 'correct'))) return 'correct'
    if (chars.some(([c, status]) => (char === c && status === 'found'))) return 'found'
    if (chars.some(([c, status]) => (char === c && status === 'missed'))) return 'missed'
  }

  return <>
    { notification.visible &&
      <div className="notification" data-type={notification.type} data-visible={notification.visible}>
        { notification.message }
      </div>
    }
    <header className="brand">
      <h1>SanaSeppo</h1>
      <div className="brand__info">{ renderStreak() }</div>
    </header>
    <main className="grid">
      { Array(rows).fill().map((_, i) =>
        <div key={i} className="grid__row">
          { Array(cols).fill().map((_, j) =>
            <div key={j} className="grid__cell">
              { getCell(i, j) }
            </div>
          )}
        </div>
      )}
    </main>
    <div className="keyboard">
      <button type="button" value="q" onClick={handleCharInput} data-selected={isSelected('q')} data-state={getStatus('q')}>Q</button>
      <button type="button" value="w" onClick={handleCharInput} data-selected={isSelected('w')} data-state={getStatus('w')}>W</button>
      <button type="button" value="e" onClick={handleCharInput} data-selected={isSelected('e')} data-state={getStatus('e')}>E</button>
      <button type="button" value="r" onClick={handleCharInput} data-selected={isSelected('r')} data-state={getStatus('r')}>R</button>
      <button type="button" value="t" onClick={handleCharInput} data-selected={isSelected('t')} data-state={getStatus('t')}>T</button>
      <button type="button" value="y" onClick={handleCharInput} data-selected={isSelected('y')} data-state={getStatus('y')}>Y</button>
      <button type="button" value="u" onClick={handleCharInput} data-selected={isSelected('u')} data-state={getStatus('u')}>U</button>
      <button type="button" value="i" onClick={handleCharInput} data-selected={isSelected('i')} data-state={getStatus('i')}>I</button>
      <button type="button" value="o" onClick={handleCharInput} data-selected={isSelected('o')} data-state={getStatus('o')}>O</button>
      <button type="button" value="p" onClick={handleCharInput} data-selected={isSelected('p')} data-state={getStatus('p')}>P</button>
      <button type="button" value="Backspace" className="del" onClick={handleCharInput}>&lt;</button>
      <button type="button" value="a" onClick={handleCharInput} data-selected={isSelected('a')} data-state={getStatus('a')}>A</button>
      <button type="button" value="s" onClick={handleCharInput} data-selected={isSelected('s')} data-state={getStatus('s')}>S</button>
      <button type="button" value="d" onClick={handleCharInput} data-selected={isSelected('d')} data-state={getStatus('d')}>D</button>
      <button type="button" value="f" onClick={handleCharInput} data-selected={isSelected('f')} data-state={getStatus('f')}>F</button>
      <button type="button" value="g" onClick={handleCharInput} data-selected={isSelected('g')} data-state={getStatus('g')}>G</button>
      <button type="button" value="h" onClick={handleCharInput} data-selected={isSelected('h')} data-state={getStatus('h')}>H</button>
      <button type="button" value="j" onClick={handleCharInput} data-selected={isSelected('j')} data-state={getStatus('j')}>J</button>
      <button type="button" value="k" onClick={handleCharInput} data-selected={isSelected('k')} data-state={getStatus('k')}>K</button>
      <button type="button" value="l" onClick={handleCharInput} data-selected={isSelected('l')} data-state={getStatus('l')}>L</button>
      <button type="button" value="ö" onClick={handleCharInput} data-selected={isSelected('ö')} data-state={getStatus('ö')}>Ö</button>
      <button type="button" value="ä" onClick={handleCharInput} data-selected={isSelected('ä')} data-state={getStatus('ä')}>Ä</button>
      <button type="button" onClick={handleShare} className="share" data-hilight={game?.isWon}>JAA</button>
      <button type="button" value="z" onClick={handleCharInput} data-selected={isSelected('z')} data-state={getStatus('z')}>Z</button>
      <button type="button" value="x" onClick={handleCharInput} data-selected={isSelected('x')} data-state={getStatus('x')}>X</button>
      <button type="button" value="c" onClick={handleCharInput} data-selected={isSelected('c')} data-state={getStatus('c')}>C</button>
      <button type="button" value="v" onClick={handleCharInput} data-selected={isSelected('v')} data-state={getStatus('v')}>V</button>
      <button type="button" value="b" onClick={handleCharInput} data-selected={isSelected('b')} data-state={getStatus('b')}>B</button>
      <button type="button" value="n" onClick={handleCharInput} data-selected={isSelected('n')} data-state={getStatus('n')}>N</button>
      <button type="button" value="m" onClick={handleCharInput} data-selected={isSelected('m')} data-state={getStatus('m')}>M</button>
      <button type="button" value="Enter" onClick={handleCharInput} className="enter">ENT</button>
    </div>
  </>
}

export default App
